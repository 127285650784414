import React, { Fragment } from "react"
import PropTypes from "prop-types"
// import _ from "lodash/string"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { navigation, get_y_coords } from './shared'

import '../styles/global.css'

const MobileMenu = ({ open }) => (
  <Transition
    show={open}
    as={Fragment}
    enter="duration-150 ease-out"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
  >
    <Popover.Panel
      focus
      static
      className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
    >
      <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="px-5 pt-4 flex items-center justify-between">
          <div>
            <a href="/" className="block py-6 text-lg hover:text-seagreen">Lakepointe Group, LLC</a>
          </div>
          <div className="-mr-2">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
              <span className="sr-only">Close main menu</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
        </div>
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navigation.map(({name, href}) => (
            href.includes('#') ?
            <a key={name} href={href} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50" onClick={e => {
              e.preventDefault()
              if (typeof window !== `undefined`) {
                window.scrollBy({
                  top: get_y_coords(href),
                  behavior: 'smooth'
                })
              }
            }}>{name}</a> :
            <Link key={name} to={href} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
              {name}
            </Link>
          ))}
        </div>
      </div>
    </Popover.Panel>
  </Transition>
)

const Layout = ({ children }) => {
  
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 bg-white lg:max-w-2xl lg:w-full min-h-screen">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>
          <Popover>
            {({ open }) => (
              <>
                <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                  <nav
                    className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                    aria-label="Global"
                  >
                    <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <a href="/" className="block py-6 text-lg hover:text-seagreen">Lakepointe Group, LLC</a>
                        <div className="-mr-2 flex items-center md:hidden">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                            <span className="sr-only">Open main menu</span>
                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                      {navigation.map(({ name, href }) => (
                        href.includes('#') ?
                        <a key={name} href={href} className="font-medium text-gray-500 hover:text-seagreen" onClick={e => {
                          e.preventDefault()
                          if (typeof window !== `undefined`) {
                            window.scrollBy({
                              top: get_y_coords(href),
                              behavior: 'smooth'
                            })
                          }
                        }}>{name}</a> :
                        <Link key={name} to={href} className="font-medium text-gray-500 hover:text-seagreen">
                          {name}
                        </Link>
                      ))}
                    </div>
                  </nav>
                </div>
                <MobileMenu open={open} />
              </>
            )}
          </Popover>
          { children }
        </div>
      </div>
      <div className="bg-seagreen lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 py-12 lg:pl-64 lg:pt-28">
        <div className="flex justify-center flex-wrap text-sm">
          <div className="bg-gray-200 rounded overflow-hidden shadow-lg">
            <StaticImage objectFit="contain" imgStyle={{ height: 'auto' }} imgClassName="block h-64 w-full" src="../images/headshot-sm.jpg" alt="Photo of Andrew Wagoner" />
            <div className="border-t-4 border-charcoal p-6 bg-aquamarine">
              <div className="text-lg text-center text-charcoal mb-2">Andrew Wagoner - Broker/Owner</div>
              <p className="text-center text-gray-500">
                Real Estate Brokerage &amp; Investments<br />Property &amp; Construction Management
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
