// shared components

export const navigation = [
  { name: 'Home', href: '/' },
  { name: 'About', href: '#about' },
  { name: 'Contact', href: '/contact' },
]

export const get_y_coords = id => {
  const elem = document.querySelector(id)
  if (elem === null) {
    window.location.assign(window.location.origin + '/' + id)
    return
  }
  return elem.getBoundingClientRect().top
}
